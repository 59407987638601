import restApiAuthorized from '@app/store/api/restApiAuthorized';
const subscriptionApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptionPlans: builder.query({
            query: () => ({
                url: '/api/v1/subscriptions_price',
                method: 'GET',
            }),
            providesTags: ['AvailableSubscriptions'],
        }),
        getCurrentSubscriptionPlans: builder.query({
            query: ({ userId }) => ({
                url: `/api/v1/user/${userId}/subscription`,
                method: 'GET',
            }),
            providesTags: ['CurrentSubscriptions'],
        }),
        createSubscription: builder.mutation({
            query: ({ userId, priceId }) => ({
                url: `/api/v1/user/${userId}/subscription`,
                method: 'POST',
                body: {
                    price_id: priceId,
                },
            }),
        }),
        createSubscriptionWithCheckout: builder.mutation({
            query: ({ userId, priceId }) => ({
                url: `/api/v1/user/${userId}/subscription_checkout`,
                method: 'POST',
                body: {
                    priceId,
                    type: 'PORTAL',
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        disableSubscription: builder.mutation({
            query: ({ userId, subscriptionId }) => ({
                url: `/api/v1/user/${userId}/subscription/${subscriptionId}/disable`,
                method: 'PATCH',
            }),
            invalidatesTags: ['CurrentSubscriptions', 'AvailableSubscriptions'],
        }),
    })
});
export default subscriptionApi;
export const { useGetSubscriptionPlansQuery, useGetCurrentSubscriptionPlansQuery, useLazyGetSubscriptionPlansQuery, useCreateSubscriptionMutation, useCreateSubscriptionWithCheckoutMutation, useDisableSubscriptionMutation, } = subscriptionApi;
